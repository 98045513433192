import { TODAS_URL } from "../../constants/constants";

import { apiSlice } from "./apiSlice";

export const todasApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    createToda: builder.mutation({
      query: ({
        title,
        description,
        address,
        latitude,
        longitude,
        createdBy,
        accountNumber,
        accessToken,
      }) => ({
        url: `${TODAS_URL}/`,
        method: "POST",
        body: {
          title,
          description,
          address,
          latitude,
          longitude,
          createdBy,
          accountNumber,
        },
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }),
    }),
    getTodas: builder.query({
      query: ({ accessToken }) => ({
        url: TODAS_URL,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }),
      keepUnusedDataFor: 5,
    }),
    getTodaById: builder.query({
      query: ({ todaId, accessToken }) => ({
        url: `${TODAS_URL}/${todaId}`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }),
      keepUnusedDataFor: 60,
    }),
  }),
});

export const { useGetTodaByIdQuery, useGetTodasQuery, useCreateTodaMutation } =
  todasApiSlice;
