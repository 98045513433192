import React from 'react';
import { Link } from 'react-router-dom';
import ContentHeader from '../../components/layouts/ContentHeader';
import MainContent from '../../components/layouts/MainContent';
import ProfileDetail from './ProfileDetail';

import ProfileForm from './ProfileForm';

const ProfilePage = () => {
  return (
    <>
      <ContentHeader pageTitle='Profile'>
        <ol className='breadcrumb'>
          <li>
            <Link to='/profile'>
              <i className='fa fa-list'></i> Profile
            </Link>
          </li>
        </ol>
      </ContentHeader>
      <MainContent>
        <div className='row'>
          <div className='col-md-5 col-lg-3'>
            <ProfileDetail />
          </div>
          {/* /.col */}
          <div className='col-md-7 col-lg-9'>
            <div className='nav-tabs-custom'>
              <ul className='nav nav-tabs'>
                <li className='active'>
                  <a href='#activity' data-toggle='tab'>
                    Activity
                  </a>
                </li>
                <li>
                  <a href='#settings' data-toggle='tab'>
                    Settings
                  </a>
                </li>
              </ul>
              <div className='tab-content'>
                <div className='active tab-pane' id='activity'></div>
                {/* /.tab-pane */}
                <div className='tab-pane' id='settings'>
                  <ProfileForm />
                </div>
                {/* /.tab-pane */}
              </div>
              {/* /.tab-content */}
            </div>
            {/* /.nav-tabs-custom */}
          </div>
          {/* /.col */}
        </div>
      </MainContent>
    </>
  );
};

export default ProfilePage;
