import React from 'react';

const ContentWrapper = ({ children }) => {
  return (
    <>
      {/* <!-- Content Wrapper. Contains page content --> */}
      <div className='content-wrapper'>{children}</div>
      {/* <!-- /.content-wrapper --> */}
    </>
  );
};

export default ContentWrapper;
