import { USERS_URL } from "../../constants/constants";

import { apiSlice } from "./apiSlice";

export const usersApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    login: builder.mutation({
      query: ({ uid, accessToken }) => ({
        url: `${USERS_URL}/login`,
        method: "POST",
        body: { uid },
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }),
    }),
    getUsers: builder.query({
      // receive access token here
      query: ({ accessToken }) => {
        return {
          url: USERS_URL,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      keepUnusedDataFor: 5,
    }),
    getUserById: builder.query({
      query: ({ userId, accessToken }) => ({
        url: `${USERS_URL}/${userId}`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }),
      keepUnusedDataFor: 60,
    }),
    getUserTransactions: builder.query({
      query: ({ userId, accessToken }) => ({
        url: `${USERS_URL}/${userId}/transactions`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }),
      keepUnusedDataFor: 60,
    }),
    getUserRideRequests: builder.query({
      query: ({ userId, accessToken }) => ({
        url: `${USERS_URL}/${userId}/ride-requests`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }),
      keepUnusedDataFor: 60,
    }),
    // UPDATE USER PHOTO
    updateUserPhoto: builder.mutation({
      query: ({ userId, photoUrl, accessToken }) => ({
        url: `${USERS_URL}/${userId}/photo`,
        method: "PATCH",
        body: { photoUrl },
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }),
    }),
    // CREATE USER
    createUser: builder.mutation({
      query: ({
        firstName,
        lastName,
        email,
        phone,
        type,
        createdBy,
        accessToken,
      }) => ({
        url: USERS_URL,
        method: "POST",
        body: { firstName, lastName, email, phone, type, createdBy },
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }),
    }),
    // GET ALL SHOPPER TYPE
    getShopperType: builder.query({
      query: ({ accessToken }) => ({
        url: `${USERS_URL}/type/shopper`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }),
    }),
  }),
});

export const {
  useGetUsersQuery,
  useGetUserByIdQuery,
  useLoginMutation,
  useGetUserTransactionsQuery,
  useGetUserRideRequestsQuery,
  useUpdateUserPhotoMutation,
  useCreateUserMutation,
  useGetShopperTypeQuery,
} = usersApiSlice;
