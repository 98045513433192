import { TODA_MEMBERS_URL } from "../../constants/constants";

import { apiSlice } from "./apiSlice";

export const todaMembersApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getTodaMembersById: builder.query({
      query: ({ todaId, accessToken }) => ({
        url: `${TODA_MEMBERS_URL}/${todaId}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }),
      keepUnusedDataFor: 5,
    }),
    removeTodaMember: builder.mutation({
      query: ({ todaId, userId, todaMemberId, accessToken }) => ({
        url: `${TODA_MEMBERS_URL}/${todaId}/remove-member`,
        method: "DELETE",
        body: {
          userId,
          todaMemberId,
        },
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }),
    }),
    addTodaMember: builder.mutation({
      query: ({ todaId, phone, role, accessToken }) => ({
        url: `${TODA_MEMBERS_URL}/${todaId}/add-member`,
        method: "POST",
        body: {
          phone,
          todaId,
          role,
        },
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }),
    }),
  }),
});

export const {
  useGetTodaMembersByIdQuery,
  useRemoveTodaMemberMutation,
  useAddTodaMemberMutation,
} = todaMembersApiSlice;
