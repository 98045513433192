import React, { useState, useRef } from "react";

import {
  LoadScript,
  GoogleMap,
  Marker,
  Autocomplete,
} from "@react-google-maps/api";
import { useEffect } from "react";

const libraries = ["places"];

const TARLAC_COORDS = {
  lat: 15.48001,
  lng: 120.594874,
};

const LocationMap = props => {
  const {
    latitude,
    longitude,
    containerStyle,
    onSelectedCoordinates,
    autocomplete,
  } = props;
  const [lat, setLat] = useState("");
  const [lng, setLng] = useState("");
  const [mapCenter, setMapCenter] = useState("");
  const autocompleteRef = useRef(null);

  const onLoad = autocomplete => {
    autocompleteRef.current = autocomplete;
  };

  const onPlaceChanged = () => {
    if (autocompleteRef.current !== null) {
      const place = autocompleteRef.current.getPlace();
      const location = place.geometry.location;
      const lat = location.lat();
      const lng = location.lng();

      console.log(lat, lng);
      // set lat lang
      setLat(lat);
      setLng(lng);
      setMapCenter({ lat, lng });
      onSelectedCoordinates({ lat, lng });
    } else {
      console.log("Autocomplete is not loaded yet!");
    }
  };

  const onClick = event => {
    if (onSelectedCoordinates == null || !onSelectedCoordinates) return;

    const clickedLat = event.latLng.lat();
    const clickedLng = event.latLng.lng();
    setLat(clickedLat);
    setLng(clickedLng);

    onSelectedCoordinates({ lat: clickedLat, lng: clickedLng });
  };

  useEffect(() => {
    setTimeout(() => {
      if (latitude && longitude) {
        setLat(latitude);
        setLng(longitude);

        setMapCenter({ lat: latitude, lng: longitude });
      }
    }, 300);
  }, [latitude, longitude]);

  const renderMap = () => {
    return (
      <GoogleMap
        mapContainerStyle={
          containerStyle || {
            width: "100%",
            height: "500px",
          }
        }
        center={mapCenter || TARLAC_COORDS}
        zoom={14}
        onClick={onClick}
      >
        {autocomplete && (
          <Autocomplete
            onLoad={onLoad}
            onPlaceChanged={onPlaceChanged}
          >
            <input
              type='text'
              placeholder='Search for a place'
              style={{
                boxSizing: `border-box`,
                border: `1px solid transparent`,
                width: `240px`,
                height: `32px`,
                padding: `0 12px`,
                borderRadius: `3px`,
                boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                fontSize: `14px`,
                outline: `none`,
                textOverflow: `ellipses`,
                position: "absolute",
                left: "50%",
                marginLeft: "-120px",
              }}
            />
          </Autocomplete>
        )}

        {lat && lng && (
          <Marker
            position={{ lat: lat, lng: lng }}
            title='Current Location'
          />
        )}
      </GoogleMap>
    );
  };

  return window.google === undefined ? (
    <LoadScript
      googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
      libraries={libraries}
    >
      {renderMap()}
    </LoadScript>
  ) : (
    renderMap()
  );
};

export default LocationMap;
