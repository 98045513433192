import React from "react";
import { Link, useParams } from "react-router-dom";
import ContentHeader from "../components/layouts/ContentHeader";
import MainContent from "../components/layouts/MainContent";
import { useState } from "react";
import { useGetTodaByIdQuery } from "../state/slices/todasApiSlice";
import { useSelector } from "react-redux";
import { selectUserInfo } from "../state/slices/authSlice";

const UpdateTodaPage = () => {
  const { id } = useParams();

  const { accessToken } = useSelector(selectUserInfo);

  const { data, isLoading, error } = useGetTodaByIdQuery({
    id,
    accessToken,
  });

  // entry data
  const { todaId, locationDetails, title, description } = data || {};

  const { latitude, longitude, address } = locationDetails || {};

  // form state
  const [enteredTitle, setEnteredTitle] = useState(title || "");
  const [enteredDescription, setEnteredDescription] = useState(
    description || "",
  );
  const [enteredAddress, setEnteredAddress] = useState(address || "");

  const submitForm = e => {
    e.preventDefault();
  };

  return (
    <>
      <ContentHeader pageTitle='Update TODA'>
        <ol className='breadcrumb'>
          <li>
            <Link to='/todas'>
              <i className='fa fa-list'></i> TODAs
            </Link>
          </li>
          <li>
            <Link to={`/todas/${todaId}`}>
              <i className='fa fa-info'></i> {title}
            </Link>
          </li>
          <li>
            <Link>
              <i className='fa fa-edit'></i> Update TODA
            </Link>
          </li>
        </ol>
      </ContentHeader>
      <MainContent>
        <div className='row'>
          <div className='col-md-8'>
            <div className='box box-default'>
              <div className='box-body'>
                <form
                  role='form'
                  onSubmit={submitForm}
                >
                  <div className='form-group'>
                    <label htmlFor='title'>Title</label>

                    <input
                      type='text'
                      className='form-control'
                      id='title'
                      placeholder='Enter title'
                      required
                      value={enteredTitle}
                      onChange={e => setEnteredTitle(e.target.value)}
                    />
                  </div>

                  <div className='form-group'>
                    <label htmlFor='description'>Description</label>

                    <textarea
                      id='description'
                      className='form-control'
                      placeholder='Enter short description'
                      value={enteredDescription}
                      onChange={e => setEnteredDescription(e.target.value)}
                    />
                  </div>

                  <div className='form-group'>
                    <label htmlFor='address'>Address</label>

                    <input
                      type='text'
                      className='form-control'
                      id='address'
                      placeholder='Enter address'
                      required
                      value={enteredAddress}
                      onChange={e => setEnteredAddress(e.target.value)}
                    />
                  </div>

                  <div className='form-group'>
                    <label htmlFor='address'>Set Map Location</label>

                    <input
                      type='text'
                      className='form-control'
                      id='address'
                      placeholder='Please point the location on the map'
                      required
                      value={`${latitude},${longitude}`}
                      readOnly
                    />
                  </div>

                  <button
                    type='submit'
                    className='btn btn-primary'
                    disabled={isLoading}
                  >
                    Submit
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </MainContent>
    </>
  );
};

export default UpdateTodaPage;
