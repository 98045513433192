import React from "react";
import { Link } from "react-router-dom";
import ContentHeader from "../components/layouts/ContentHeader";
import MainContent from "../components/layouts/MainContent";
import { useState } from "react";
import LocationMap from "../components/common/LocationMap";
import { useCreateTodaMutation } from "../state/slices/todasApiSlice";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectUserInfo } from "../state/slices/authSlice";
import { toast } from "react-toastify";

const CreateTodaPage = () => {
  const { id, accessToken } = useSelector(selectUserInfo);
  const navigate = useNavigate();

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [address, setAddress] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [accountNumber, setAccountNumber] = useState("");

  const [createToda, { isLoading }] = useCreateTodaMutation();

  const selectedCoordinates = latLng => {
    const { lat, lng } = latLng;

    setLatitude(lat);
    setLongitude(lng);
  };

  const submitForm = async e => {
    e.preventDefault();

    if (!latitude || !longitude) {
      toast.error("TODA location is required");
      return;
    }

    try {
      const { id: todaId } = await createToda({
        title,
        description,
        address,
        latitude,
        longitude,
        accountNumber,
        createdBy: id,
        accessToken,
      }).unwrap();

      toast.success(`TODA ${title} is created.`);

      navigate(`/todas/${todaId}`);
    } catch (e) {
      console.log(e);
      if (e.data.errors) {
        toast.error(e.data.errors[0].message);
      } else if (e.data.message) {
        toast.error(e.data.message);
      } else {
        toast.error("Something went wrong, please try again.");
      }
    }
  };

  return (
    <>
      <ContentHeader pageTitle='Create TODA'>
        <ol className='breadcrumb'>
          <li>
            <Link to='/todas'>
              <i className='fa fa-list'></i> TODAs
            </Link>
          </li>
          <li>
            <Link>
              <i className='fa fa-plus'></i> Create TODA
            </Link>
          </li>
        </ol>
      </ContentHeader>
      <MainContent>
        <div className='row'>
          <div className='col-md-4'>
            <div className='box box-solid'>
              <div className='box-body'>
                <form
                  role='form'
                  onSubmit={submitForm}
                >
                  <div className='form-group'>
                    <label htmlFor='title'>Title</label>

                    <input
                      type='text'
                      className='form-control'
                      id='title'
                      placeholder='Enter title'
                      required
                      value={title}
                      onChange={e => setTitle(e.target.value)}
                    />
                  </div>

                  <div className='form-group'>
                    <label htmlFor='description'>Description</label>

                    <textarea
                      id='description'
                      className='form-control'
                      placeholder='Enter short description'
                      value={description}
                      onChange={e => setDescription(e.target.value)}
                    />
                  </div>

                  <div className='form-group'>
                    <label htmlFor='accountNumber'>
                      Account Number{" "}
                      <small class='text-muted'>
                        (Phone number ex. 9238964659)
                      </small>
                    </label>

                    <input
                      type='text'
                      className='form-control'
                      id='accountNumber'
                      placeholder='Enter Account Number'
                      required
                      value={accountNumber}
                      onChange={e => setAccountNumber(e.target.value)}
                    />
                  </div>

                  <div className='form-group'>
                    <label htmlFor='address'>Address</label>

                    <input
                      type='text'
                      className='form-control'
                      id='address'
                      placeholder='Enter address'
                      required
                      value={address}
                      onChange={e => setAddress(e.target.value)}
                    />
                  </div>

                  <div className='form-group'>
                    <label htmlFor='address'>Set Map Location</label>

                    <input
                      type='text'
                      className='form-control'
                      id='address'
                      placeholder='Please point the location on the map'
                      required
                      value={`${latitude},${longitude}`}
                      readOnly
                    />
                  </div>

                  <button
                    type='submit'
                    className='btn btn-primary'
                  >
                    Submit
                  </button>
                </form>
              </div>
            </div>
          </div>
          <div className='col-md-8'>
            <LocationMap
              autocomplete
              onSelectedCoordinates={selectedCoordinates}
            />
          </div>
        </div>
      </MainContent>
    </>
  );
};

export default CreateTodaPage;
