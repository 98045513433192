import React from 'react';

const ProfileForm = () => {
  const handleSubmit = async e => {
    e.preventDefault();

    console.log('update user profile');
  };

  return (
    <form className='form-horizontal' onSubmit={handleSubmit}>
      <div className='form-group'>
        <label htmlFor='firstName' className='col-sm-2 control-label'>
          First Name
        </label>
        <div className='col-sm-10'>
          <input type='text' className='form-control' id='firstName' />
        </div>
      </div>
      <div className='form-group'>
        <label htmlFor='lastName' className='col-sm-2 control-label'>
          Last Name
        </label>
        <div className='col-sm-10'>
          <input type='text' className='form-control' id='lastName' />
        </div>
      </div>
      <div className='form-group'>
        <label htmlFor='phone' className='col-sm-2 control-label'>
          Phone Number
        </label>
        <div className='col-sm-10'>
          <input type='text' className='form-control' id='phone' />
        </div>
      </div>
      <div className='form-group'>
        <label htmlFor='inputEmail' className='col-sm-2 control-label'>
          Email
        </label>
        <div className='col-sm-10'>
          <input
            type='email'
            className='form-control'
            id='inputEmail'
            autoComplete='username'
          />
        </div>
      </div>
      <div className='form-group'>
        <label htmlFor='password' className='col-sm-2 control-label'>
          Password
        </label>
        <div className='col-sm-10'>
          <input
            type='password'
            className='form-control'
            id='password'
            autoComplete='current-password'
          />
        </div>
      </div>

      <div className='form-group'>
        <label
          htmlFor='confirmPassword'
          className='col-sm-2 control-label'
        >
          Confirm Password
        </label>
        <div className='col-sm-10'>
          <input
            type='password'
            className='form-control'
            id='confirmPassword'
            autoComplete='new-password'
          />
        </div>
      </div>

      <div className='form-group'>
        <div className='col-sm-offset-2 col-sm-10'>
          <button type='submit' className='btn btn-danger'>
            Submit
          </button>
        </div>
      </div>
    </form>
  );
};

export default ProfileForm;
