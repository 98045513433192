import { createSlice } from "@reduxjs/toolkit";
import { USER_INFO_KEY } from "../../constants/constants";

const userInfoFromLocalStorage = localStorage.getItem(USER_INFO_KEY);

const initialState = {
  userInfo: userInfoFromLocalStorage
    ? JSON.parse(userInfoFromLocalStorage)
    : null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setCredentials: (state, action) => {
      const userInfo = action.payload;
      state.userInfo = userInfo;

      localStorage.setItem(USER_INFO_KEY, JSON.stringify(userInfo));
    },
    setAccessToken: (state, action) => {
      const accessToken = action.payload;

      const userInfoUpdatedAccessToken = {
        ...state.userInfo,
        accessToken,
      };

      state.userInfo = userInfoUpdatedAccessToken;

      localStorage.setItem(
        USER_INFO_KEY,
        JSON.stringify(userInfoUpdatedAccessToken),
      );
    },
    setPhotoUrl: (state, action) => {
      const photoUrl = action.payload;

      const userInfoUpdatedPhotoUrl = {
        ...state.userInfo,
        photoUrl,
      };

      state.userInfo = userInfoUpdatedPhotoUrl;

      localStorage.setItem(
        USER_INFO_KEY,
        JSON.stringify(userInfoUpdatedPhotoUrl),
      );
    },
    destroyCredentials: (state, action) => {
      state.userInfo = null;
      localStorage.removeItem(USER_INFO_KEY);
    },
  },
});

export const {
  setCredentials,
  destroyCredentials,
  setAccessToken,
  setPhotoUrl,
} = authSlice.actions;

export const selectUserInfo = state => state.auth.userInfo;

export default authSlice;
