import React, { useRef, useState } from "react";
import { storage } from "../../config/firebase";
import { STORAGE_USER_PHOTO_FOLDER } from "../../constants/constants";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { selectUserInfo, setPhotoUrl } from "../../state/slices/authSlice";
import profilePlaceholder from "../../assets/profile_placeholder.jpg";
import { useDispatch, useSelector } from "react-redux";
import { useUpdateUserPhotoMutation } from "../../state/slices/usersApiSlice";

const ProfileDetail = () => {
  const userInfo = useSelector(selectUserInfo);
  const { id, email, firstName, lastName, phone, photoUrl, accessToken } =
    userInfo || {};

  const dispatch = useDispatch();

  const [photo, setPhoto] = useState();
  const closeRef = useRef();
  const formRef = useRef();

  const [updateUserPhoto, { isLoading }] = useUpdateUserPhotoMutation();

  const handleSubmit = async e => {
    e.preventDefault();

    if (!photo) return;

    const photoRef = ref(storage, `${STORAGE_USER_PHOTO_FOLDER}/${id}`);

    try {
      const res = await uploadBytes(photoRef, photo);

      if (res.metadata.fullPath) {
        const photoUrl = await getDownloadURL(photoRef);

        await updateUserPhoto({ userId: id, photoUrl, accessToken });

        dispatch(setPhotoUrl(photoUrl));
        // close modal and reset form
        setPhoto("");
        closeRef.current.click();
        formRef.current.reset();
      } else {
        console.log("fullPath is not present");
      }
    } catch (e) {
      console.log(`Error uploading image: ${e}`);
    }
  };

  return (
    <div className='box box-danger'>
      <div
        className='modal fade'
        id='modal-default'
        style={{ display: "none" }}
      >
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <button
                type='button'
                className='close'
                data-dismiss='modal'
                aria-label='Close'
              >
                <span aria-hidden='true'>×</span>
              </button>
              <h4 className='modal-title'>Upload Profile Picture</h4>
            </div>
            <div className='modal-body'>
              <form
                className='form-horizontal'
                ref={formRef}
              >
                {photo && (
                  <div className='form-group'>
                    <label className='col-sm-2 control-label'>Preview</label>
                    <div className='col-sm-10'>
                      <div
                        style={{
                          width: 150,
                          height: 150,
                          overflow: "hidden",
                          background: "#f3f3f3",
                        }}
                      >
                        <img
                          src={URL.createObjectURL(photo)}
                          style={{
                            width: "100%",
                            height: "100%",
                            borderRadius: "50%",
                            objectFit: "cover",
                          }}
                          alt='user preview'
                        />
                      </div>
                    </div>
                  </div>
                )}

                <div className='form-group'>
                  <label
                    htmlFor='photo'
                    className='col-sm-2 control-label'
                  >
                    Photo
                  </label>
                  <div className='col-sm-10'>
                    <input
                      type='file'
                      className='form-control'
                      onChange={e => setPhoto(e.target.files[0])}
                      id='photo'
                    />

                    <small>
                      Please select an image with a resolution of 150x150
                      pixels.
                    </small>
                  </div>
                </div>

                <div className='form-group'>
                  <div className='col-sm-offset-2 col-sm-10'>
                    <button
                      type='submit'
                      onClick={handleSubmit}
                      className='btn btn-success'
                    >
                      Save
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div className='modal-footer'>
              <button
                type='button'
                className='btn btn-danger pull-right'
                data-dismiss='modal'
                ref={closeRef}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className='box-body box-profile'>
        <img
          className='profile-user-img img-responsive img-circle'
          style={{
            height: "100px",
            width: "100px",
          }}
          src={photoUrl ? photoUrl : profilePlaceholder}
          alt='User profile '
        />
        <h3 className='profile-username text-center'>
          {`${firstName} ${lastName}`}
        </h3>
        <p className='text-muted text-center'>Administrator</p>
        <ul className='list-group list-group-unbordered'>
          <li className='list-group-item'>
            <b>ID</b> <span className='pull-right'>{id}</span>
          </li>
          <li className='list-group-item'>
            <b>Email</b> <span className='pull-right'>{email}</span>
          </li>
          <li className='list-group-item'>
            <b>Phone</b> <span className='pull-right'>+63{phone}</span>
          </li>
          <li className='list-group-item '>
            <button
              type='button'
              className='btn btn-primary btn-md center-block'
              data-toggle='modal'
              data-target='#modal-default'
            >
              Change Profile Picture
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default ProfileDetail;
