const ModalHeader = ({ title, onClose }) => {
  return (
    <div className='modal-header'>
      <button
        type='button'
        className='close'
        data-dismiss='modal'
        aria-label='Close'
        onClick={onClose}
      >
        <span aria-hidden='true'>×</span>
      </button>
      <h4 className='modal-title'>{title}</h4>
    </div>
  );
};

export default ModalHeader;
