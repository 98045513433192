import React from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import AppLayout from "./components/layouts/AppLayout";
import SinglePageLayout from "./components/layouts/SinglePageLayout";
import PrivateRoute from "./components/common/PrivateRoute";
import HomePage from "./pages/HomePage";
import LoginPage from "./pages/LoginPage";
import TodasPage from "./pages/TodasPage";
import UserDetailsPage from "./pages/UserDetailsPage/UserDetailsPage";
import UsersPage from "./pages/UsersPage";
import TodaDetailsPage from "./pages/TodaDetailsPage/TodaDetailsPage";
import RideRequestsPage from "./pages/RideRequestsPage";
import TransactionsPage from "./pages/Transactions";
import PaymentsPage from "./pages/Payments";
import RideDetailPage from "./pages/RideDetailPage";
import ProfilePage from "./pages/ProfilePage/ProfilePage";
import CreateTodaPage from "./pages/CreateTodaPage";
import UpdateTodaPage from "./pages/UpdateTodaPage";
import TodaManageMembersPage from "./pages/TodaManageMembersPage";
import ShopperGroupsPage from "./pages/ShopperGroupsPage";
import CreateShopperGroupPage from "./pages/CreateShopperGroupPage";
import ShopperGroupDetailsPage from "./pages/ShopperGroupDetailsPage/ShopperGroupDetailsPage";
import ShopperGroupManageMembersPage from "./pages/ShopperGroupManageMembersPage";
import CreateUserPage from "./pages/CreateUserPage";
import BalanceRequestsPage from "./pages/BalanceRequestsPage/BalanceRequestsPage";
import BalanceRequestDetailsPage from "./pages/BalanceRequestDetailsPage/BalanceRequestDetailsPage";
import PalengkesPage from "./pages/PalengkesPage";
import PalengkeDetailsPage from "./pages/PalengkeDetailsPage/PalengkeDetailsPage";
import CreatePalengkePage from "./pages/CreatePalengkePage";

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route element={<PrivateRoute />}>
        <Route element={<AppLayout />}>
          <Route
            path='/'
            index={true}
            element={<HomePage />}
          />
          <Route
            path='/profile'
            element={<ProfilePage />}
          />
          <Route
            path='/users/:id'
            element={<UserDetailsPage />}
          />
          <Route
            path='/users/create-user'
            element={<CreateUserPage />}
          />
          <Route
            path='/users'
            element={<UsersPage />}
          />
          <Route
            path='/todas/:id'
            element={<TodaDetailsPage />}
          />
          <Route
            path='/todas'
            element={<TodasPage />}
          />
          <Route
            path='/todas/add'
            element={<CreateTodaPage />}
          />
          <Route
            path='/todas/:id/edit'
            element={<UpdateTodaPage />}
          />
          <Route
            path='/todas/:id/manage-members'
            element={<TodaManageMembersPage />}
          />
          <Route
            path='/ride-requests/:id'
            element={<RideDetailPage />}
          />
          <Route
            path='/ride-requests'
            element={<RideRequestsPage />}
          />
          <Route
            path='/transactions'
            element={<TransactionsPage />}
          />
          <Route
            path='/payments'
            element={<PaymentsPage />}
          />
          <Route
            path='/shopper-groups/add'
            element={<CreateShopperGroupPage />}
          />
          <Route
            path='/shopper-groups'
            element={<ShopperGroupsPage />}
          />
          <Route
            path='/shopper-groups/:id'
            element={<ShopperGroupDetailsPage />}
          />
          <Route
            path='/shopper-groups/:id/manage-members'
            element={<ShopperGroupManageMembersPage />}
          />
          <Route
            path='/balance-requests'
            element={<BalanceRequestsPage />}
          />
          <Route
            path='/balance-requests/:id'
            element={<BalanceRequestDetailsPage />}
          />
          <Route
            path='/palengkes/:id'
            element={<PalengkeDetailsPage />}
          />
          <Route
            path='/palengkes/add'
            element={<CreatePalengkePage />}
          />
          <Route
            path='/palengkes'
            element={<PalengkesPage />}
          />
        </Route>
      </Route>
      <Route element={<SinglePageLayout />}>
        <Route
          path='/login'
          element={<LoginPage />}
        />
      </Route>
    </>,
  ),
);

const AppRouter = () => {
  return <RouterProvider router={router} />;
};

export default AppRouter;
