import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import CircleLoader from "../components/common/loaders/CircleLoader";
import Message from "../components/common/Message";
import ContentHeader from "../components/layouts/ContentHeader";
import MainContent from "../components/layouts/MainContent";
import { selectUserInfo } from "../state/slices/authSlice";
import {
  useGetShopperGroupMembersByIdQuery,
  useAddShopperGroupMemberMutation,
  useRemoveShopperGroupMemberMutation,
} from "../state/slices/shopperGroupsApiSlice";
import { useGetShopperTypeQuery } from "../state/slices/usersApiSlice";
import { toast } from "react-toastify";

const ShopperGroupManageMembersPage = () => {
  const { id } = useParams();
  const closeRef = useRef();

  const { accessToken } = useSelector(selectUserInfo);

  // add/remove member
  const [userId, setUserId] = useState("");

  const [removeMember, { isLoading: isLoadingRemove }] =
    useRemoveShopperGroupMemberMutation();
  const [addMember, { isLoading: isLoadingAdd }] =
    useAddShopperGroupMemberMutation();

  const {
    data: members,
    isLoading,
    error,
    refetch: refetchMembers,
  } = useGetShopperGroupMembersByIdQuery({
    shopperGroupId: id,
    accessToken,
  });

  const {
    data: shoppers,
    isLoading: isLoadingShoppers,
    error: errorShoppers,
    refetch: refetchShoppers,
  } = useGetShopperTypeQuery({ id, accessToken });

  const deleteHandler = data => {
    setUserId(data.userId);
  };

  const cancelHandler = () => {
    setUserId("");
  };

  const executeRemoveMember = async () => {
    try {
      await removeMember({
        shopperGroupId: id,
        userId,
        accessToken,
      });

      closeRef.current.click();
      setUserId("");

      // refetch members
      refetchMembers();

      // refetch shoppers to update the select field
      refetchShoppers();

      toast.success("Successfully removed member.");
    } catch (e) {
      toast.error("Unable to remove member.");
    }
  };

  const submitForm = async e => {
    e.preventDefault();

    if (userId === "") {
      toast.error("Please select a user.");
      return;
    }

    try {
      const data = await addMember({
        shopperGroupId: id,
        userId,
        accessToken,
      }).unwrap();

      console.log(data);

      setUserId("");

      refetchMembers();

      toast.success("Successfully added member.");
    } catch (e) {
      toast.error("Unable to add member.");
    }
  };

  return (
    <>
      <ContentHeader pageTitle='Shopper Group Manage Members'>
        <ol className='breadcrumb'>
          <li>
            <Link to='/shopper-groups'>
              <i className='fa fa-list'></i> Shopper Groups
            </Link>
          </li>

          <li>
            <Link to={`/shopper-groups/${id}`}>
              <i className='fa fa-info'></i> Shopper Groups Detail
            </Link>
          </li>
          <li>
            <Link>
              <i className='fa fa-users'></i> Manage Members
            </Link>
          </li>
        </ol>
      </ContentHeader>
      <MainContent>
        <div className='box box-solid'>
          <div className='box-header'>
            <h3 className='box-title'>
              <i className='fa fa-users'></i> Shopper Group Members
            </h3>

            <button
              className='btn btn-sm btn-success pull-right'
              type='button'
              data-toggle='modal'
              data-target='#modal-add'
            >
              <i className='fa fa-plus'></i> Add Member
            </button>
          </div>
          <div className='box-body table-responsive no-padding'>
            {isLoading && (
              <CircleLoader
                title='Loading...'
                size={50}
                center
              />
            )}

            {error && (
              <Message
                title='Error'
                variant='danger'
              >
                {error.data.message || error.error}
              </Message>
            )}

            {!isLoading && !error && (
              <>
                <table className='table table-hover'>
                  <tbody>
                    <tr>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Phone Number</th>
                      <th>Actions</th>
                    </tr>

                    {members.length === 0 ? (
                      <tr>
                        <td
                          colSpan={5}
                          align='center'
                        >
                          <strong>NO RECORDS FOUND.</strong>
                        </td>
                      </tr>
                    ) : (
                      members.map(member => {
                        const { id, firstName, lastName, phone, email } =
                          member;

                        return (
                          <tr key={id}>
                            <td>
                              {firstName} {lastName}
                            </td>
                            <td>{email}</td>

                            <td>+63{phone}</td>
                            <td>
                              <button
                                className='btn btn-sm btn-danger'
                                type='button'
                                data-toggle='modal'
                                data-target='#modal-remove'
                                onClick={() =>
                                  deleteHandler({
                                    userId: id,
                                  })
                                }
                              >
                                <i className='fa fa-close'></i> Remove
                              </button>
                            </td>
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                </table>
              </>
            )}
          </div>
        </div>
      </MainContent>

      {/* add modal */}
      <div
        className='modal fade'
        id='modal-add'
        style={{ display: "none" }}
      >
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <button
                type='button'
                className='close'
                data-dismiss='modal'
                aria-label='Close'
              >
                <span aria-hidden='true'>×</span>
              </button>
              <h4 className='modal-title'>Add Member</h4>
            </div>
            <div className='modal-body'>
              <form>
                <div className='form-group'>
                  <label htmlFor='shopper'>Shopper</label>
                  {isLoadingShoppers && (
                    <CircleLoader
                      title='Loading...'
                      size={50}
                      center
                    />
                  )}

                  {errorShoppers && (
                    <Message
                      title='Error'
                      variant='danger'
                    >
                      {errorShoppers?.data?.message || errorShoppers?.error}
                    </Message>
                  )}

                  {!isLoadingShoppers && !errorShoppers && (
                    <select
                      className='form-control'
                      id='shopper'
                      onChange={e => setUserId(e.target.value)}
                      required
                      value={userId}
                    >
                      <option value=''>Select User</option>
                      {
                        // we only want to show shoppers that doesn't have a shopperGroupId
                        shoppers
                          .filter(user => user.shopperGroupId == null)
                          .map(user => (
                            <option
                              key={user.id}
                              value={user.id}
                            >
                              {user.firstName} {user.lastName} - {user.email}
                            </option>
                          ))
                      }
                    </select>
                  )}
                </div>
              </form>

              <button
                type='button'
                className='btn btn-success '
                data-dismiss='modal'
                onClick={e => submitForm(e)}
              >
                Submit
              </button>

              <button
                type='button'
                className='btn btn-danger pull-right'
                data-dismiss='modal'
                onClick={cancelHandler}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* remove modal */}
      <div
        className='modal fade'
        id='modal-remove'
        style={{ display: "none" }}
      >
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <button
                type='button'
                className='close'
                data-dismiss='modal'
                aria-label='Close'
              >
                <span aria-hidden='true'>×</span>
              </button>
              <h4 className='modal-title'>Remove Member</h4>
            </div>
            <div className='modal-body'>
              <p>You do want to remove this member?</p>

              <button
                type='button'
                className='btn btn-success '
                data-dismiss='modal'
                onClick={() => executeRemoveMember()}
              >
                Yes
              </button>

              <button
                type='button'
                className='btn btn-danger pull-right'
                data-dismiss='modal'
                ref={closeRef}
                onClick={cancelHandler}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ShopperGroupManageMembersPage;
