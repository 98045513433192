import { useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { selectUserInfo } from "../state/slices/authSlice";
import { useCreatePalengkeMutation } from "../state/slices/palengkesApiSlice";

import ContentHeader from "../components/layouts/ContentHeader";
import MainContent from "../components/layouts/MainContent";

import { toast } from "react-toastify";
import LocationMap from "../components/common/LocationMap";

const CreatePalengkePage = () => {
  const { id, firstName, lastName, phone, accessToken } =
    useSelector(selectUserInfo);
  const navigate = useNavigate();
  const [createPalengke, { isLoading }] = useCreatePalengkeMutation();

  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");

  const setCoordinates = latLng => {
    const { lat, lng } = latLng;

    setLatitude(lat);
    setLongitude(lng);
  };

  const _submitForm = async e => {
    e.preventDefault();

    if (!latitude || !longitude) {
      toast.error("Palengke location is required");
      return;
    }

    const palengke = {
      name,
      address,
      latitude,
      longitude,
      createdBy: {
        id,
        firstName,
        lastName,
        phone,
      },
    };

    try {
      const { id: palengkeId } = await createPalengke({
        palengke,
        accessToken,
      }).unwrap();

      toast.success(`Palengke ${name} is created.`);

      navigate(`/palengkes/${palengkeId}`);
    } catch (e) {
      console.log(e);
      if (e.data.errors) {
        toast.error(e.data.errors[0].message);
      } else if (e.data.message) {
        toast.error(e.data.message);
      } else {
        toast.error("Failed to create Palengke.");
      }
    }
  };

  return (
    <>
      <ContentHeader pageTitle='Create Palengke'>
        <ol className='breadcrumb'>
          <li>
            <Link to='/palengkes'>
              <i className='fa fa-list'></i> Palengkes
            </Link>
          </li>
          <li>
            <Link>
              <i className='fa fa-plus'></i> Create Palengke
            </Link>
          </li>
        </ol>
      </ContentHeader>
      <MainContent>
        <div className='row'>
          <div className='col-md-6'>
            <div className='box box-solid'>
              <div className='box-header with-border'>
                <h3 className='box-title'>Palengke Details</h3>
              </div>
              <div className='box-body'>
                <form
                  role='form'
                  onSubmit={_submitForm}
                >
                  <div className='form-group'>
                    <label htmlFor='name'>Name</label>

                    <input
                      type='text'
                      className='form-control'
                      id='name'
                      placeholder='Enter name'
                      required
                      value={name}
                      onChange={e => setName(e.target.value)}
                    />
                  </div>

                  <div className='form-group'>
                    <label htmlFor='address'>Address</label>

                    <input
                      type='text'
                      className='form-control'
                      id='address'
                      placeholder='Enter address'
                      required
                      value={address}
                      onChange={e => setAddress(e.target.value)}
                    />
                  </div>

                  <div className='form-group'>
                    <label htmlFor='address'>Set Map Location</label>

                    <input
                      type='text'
                      className='form-control'
                      id='address'
                      placeholder='Please point the location on the map'
                      required
                      value={`${latitude},${longitude}`}
                      readOnly
                    />
                  </div>

                  <button
                    type='submit'
                    className='btn btn-primary'
                  >
                    Submit
                  </button>
                </form>
              </div>
            </div>
          </div>
          <div className='col-md-6'>
            <div className='box box-solid'>
              <div className='box-header with-border'>
                <h3 className='box-title'>Select Coordinates</h3>
              </div>
              <div className='box-body'>
                <LocationMap
                  autocomplete
                  onSelectedCoordinates={setCoordinates}
                />
              </div>
            </div>
          </div>
        </div>
      </MainContent>
    </>
  );
};

export default CreatePalengkePage;
