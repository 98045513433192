import React from "react";
import MainContent from "../components/layouts/MainContent";
import ContentHeader from "../components/layouts/ContentHeader";
import InfoBox from "../components/common/InfoBox";
import { useGetUsersQuery } from "../state/slices/usersApiSlice";
import { useGetTodasQuery } from "../state/slices/todasApiSlice";
import CircleLoader from "../components/common/loaders/CircleLoader";
import Message from "../components/common/Message";
import { useSelector } from "react-redux";
import { selectUserInfo } from "../state/slices/authSlice";

const HomePage = () => {
  const { accessToken } = useSelector(selectUserInfo);

  const { data: users, isLoading, error } = useGetUsersQuery({ accessToken });

  const {
    data: todas,
    isLoading: isLoadingTodas,
    error: errorTodas,
  } = useGetTodasQuery({ accessToken });

  const passengerCount =
    users && users.filter(user => user.type === "passenger").length;

  const driversCount =
    users && users.filter(user => user.type === "driver").length;

  const dispatchers =
    users && users.filter(user => user.type === "dispatcher").length;

  const todasCount = todas && todas.length;

  return (
    <>
      <ContentHeader pageTitle='Dashboard'>
        <ol className='breadcrumb'>
          <li>
            <div>
              <i className='fa fa-dashboard'></i> Dashboard
            </div>
          </li>
        </ol>
      </ContentHeader>
      <MainContent>
        {error && <Message>{error?.data?.message}</Message>}
        {errorTodas && <Message>{errorTodas?.data?.message}</Message>}

        <div className='row'>
          <div className='col-md-3 col-sm-6 col-xs-12'>
            <InfoBox
              iconClass='fa fa-user'
              title='Passengers'
              color='aqua'
            >
              {isLoading ? <CircleLoader /> : passengerCount}
            </InfoBox>
          </div>

          <div className='col-md-3 col-sm-6 col-xs-12'>
            <InfoBox
              iconClass='fa fa-user'
              title='Drivers'
              color='red'
            >
              {isLoading ? <CircleLoader /> : driversCount}
            </InfoBox>
          </div>

          <div className='col-md-3 col-sm-6 col-xs-12'>
            <InfoBox
              iconClass='fa fa-user'
              title='Dispatchers'
              color='green'
            >
              {isLoading ? <CircleLoader /> : dispatchers}
            </InfoBox>
          </div>

          <div className='col-md-3 col-sm-6 col-xs-12'>
            <InfoBox
              iconClass='fa fa-list'
              title='TODAs'
              color='yellow'
            >
              {isLoadingTodas ? <CircleLoader /> : todasCount}
            </InfoBox>
          </div>
        </div>
      </MainContent>
    </>
  );
};

export default HomePage;
