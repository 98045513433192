import { WALLETS_URL } from "../../constants/constants";

import { apiSlice } from "./apiSlice";

export const walletsApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getWallets: builder.query({
      query: ({ accessToken }) => ({
        url: WALLETS_URL,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }),
      keepUnusedDataFor: 5,
    }),
    getWalletById: builder.query({
      query: ({ id, accessToken }) => ({
        url: `${WALLETS_URL}/${id}`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }),
      keepUnusedDataFor: 60,
    }),
  }),
});

export const { useGetWalletsQuery, useGetWalletByIdQuery } = walletsApiSlice;
