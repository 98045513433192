import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import ContentHeader from "../../components/layouts/ContentHeader";
import MainContent from "../../components/layouts/MainContent";
import { useParams, useNavigate } from "react-router-dom";
import UserProfile from "./UserProfile";
import UserWallet from "./UserWallet";
import UserRideHistory from "./UserRideHistory";
import UserTransactions from "./UserTransactions";

const UserDetailsPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!id) {
      navigate("/users");
    }
  }, [navigate, id]);

  return (
    <>
      <ContentHeader pageTitle='User Detail'>
        <ol className='breadcrumb'>
          <li>
            <Link to='/users'>
              <i className='fa fa-users'></i> Users
            </Link>
          </li>

          <li className='active'>
            <Link to='#'>
              <i className='fa fa-info'></i> User Details
            </Link>
          </li>
        </ol>
      </ContentHeader>

      <MainContent>
        <div className='row'>
          <div className='col-md-6'>
            <UserProfile userId={id} />
          </div>

          <div className='col-md-6'>
            <UserWallet walletId={id} />
          </div>
        </div>

        <div className='row'>
          <div className='col-md-12'>
            <UserTransactions userId={id} />
          </div>
        </div>

        <div className='row'>
          <div className='col-md-12'>
            <UserRideHistory userId={id} />
          </div>
        </div>
      </MainContent>
    </>
  );
};

export default UserDetailsPage;
