import React from 'react';

const RideRequestStatus = ({ status }) => {
  let labelVariant = 'info';

  if (status === 'rejected' || status === 'cancelled') {
    labelVariant = 'danger';
  } else if (status === 'ended') {
    labelVariant = 'success';
  } else {
    labelVariant = 'info';
  }

  return (
    <span className={`label label-${labelVariant}`}>
      {status.toUpperCase()}
    </span>
  );
};

export default RideRequestStatus;
