import { SHOPPER_GROUPS_URL } from "../../constants/constants";

import { apiSlice } from "./apiSlice";

export const shopperGroupsApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    createShopperGroup: builder.mutation({
      query: ({
        name,
        storeOrPalengkeId,
        type,
        accountNumber,
        createdBy,
        accessToken,
      }) => ({
        url: `${SHOPPER_GROUPS_URL}/`,
        method: "POST",
        body: {
          name,
          storeOrPalengkeId,
          type,
          accountNumber,
          createdBy,
        },
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }),
    }),
    getShopperGroups: builder.query({
      query: ({ accessToken }) => ({
        url: SHOPPER_GROUPS_URL,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }),
      keepUnusedDataFor: 60,
    }),
    getShopperGroupById: builder.query({
      query: ({ id, accessToken }) => ({
        url: `${SHOPPER_GROUPS_URL}/${id}`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }),
      keepUnusedDataFor: 60,
    }),
    getShopperGroupMembersById: builder.query({
      query: ({ shopperGroupId, accessToken }) => ({
        url: `${SHOPPER_GROUPS_URL}/${shopperGroupId}/members`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }),
      keepUnusedDataFor: 60,
    }),
    addShopperGroupMember: builder.mutation({
      query: ({ shopperGroupId, userId, accessToken }) => ({
        url: `${SHOPPER_GROUPS_URL}/${shopperGroupId}/members`,
        method: "POST",
        body: {
          userId,
        },
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }),
    }),
    removeShopperGroupMember: builder.mutation({
      query: ({ shopperGroupId, userId, accessToken }) => ({
        url: `${SHOPPER_GROUPS_URL}/${shopperGroupId}/members/${userId}`,
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }),
    }),
  }),
});

export const {
  useGetShopperGroupsQuery,
  useGetShopperGroupByIdQuery,
  useCreateShopperGroupMutation,
  useGetShopperGroupMembersByIdQuery,
  useAddShopperGroupMemberMutation,
  useRemoveShopperGroupMemberMutation,
} = shopperGroupsApiSlice;
