import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  selectUserInfo,
  destroyCredentials,
} from "../../state/slices/authSlice";
import profilePlaceholder from "../../assets/profile_placeholder.jpg";

const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userInfo = useSelector(selectUserInfo);

  const fullName = `${userInfo.firstName} ${userInfo.lastName}`;
  const photoUrl = userInfo.photoUrl ? userInfo.photoUrl : profilePlaceholder;

  const logoutHandler = () => {
    dispatch(destroyCredentials());
    navigate("/login");
  };

  return (
    <header className='main-header'>
      <Link to='/'>
        <div className='logo'>
          {/* <!-- mini logo for sidebar mini 50x50 pixels --> */}
          <span className='logo-mini'>
            <b>SM</b>
          </span>
          {/* <!-- logo for regular state and mobile devices --> */}
          <span className='logo-lg'>
            <b>Sakay Me</b>
          </span>
        </div>
      </Link>

      {/* <!-- Header Navbar: style can be found in header.less --> */}
      <nav className='navbar navbar-static-top'>
        {/* <!-- Sidebar toggle button--> */}
        <a
          href='#'
          className='sidebar-toggle'
          data-toggle='push-menu'
          role='button'
        >
          <span className='sr-only'>Toggle navigation</span>
        </a>

        {/* <!-- Navbar Right Menu --> */}
        <div className='navbar-custom-menu'>
          <ul className='nav navbar-nav'>
            {/* <!-- User Account: style can be found in dropdown.less --> */}
            <li className='dropdown user user-menu'>
              <a
                href='#'
                className='dropdown-toggle'
                data-toggle='dropdown'
              >
                <img
                  src={photoUrl}
                  className='user-image'
                  alt='User Image'
                />
                <span className='hidden-xs'>{fullName}</span>
              </a>
              <ul className='dropdown-menu'>
                {/* <!-- User image --> */}
                <li className='user-header'>
                  <img
                    src={photoUrl}
                    className='img-circle header-user-image'
                    alt='User Image'
                  />

                  <p>
                    {fullName}
                    <small>Admin</small>
                  </p>
                </li>

                {/* <!-- Menu Footer--> */}
                <li className='user-footer'>
                  <div className='pull-left'>
                    <Link
                      to='/profile'
                      className='btn btn-default btn-flat'
                    >
                      Profile
                    </Link>
                  </div>
                  <div className='pull-right'>
                    <button
                      href='#'
                      className='btn btn-danger btn-flat'
                      onClick={logoutHandler}
                    >
                      Logout
                    </button>
                  </div>
                </li>
              </ul>
            </li>
            {/* <li>
              <a href='#' data-toggle='control-sidebar'>
                <i className='fa fa-gears'></i>
              </a>
            </li> */}
          </ul>
        </div>
      </nav>
    </header>
  );
};

export default Header;
