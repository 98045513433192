import React from 'react';

const AccountType = ({ type }) => {
  let labelVariant = 'info';

  if (type === 'driver') {
    labelVariant = 'danger';
  } else if (type === 'dispatcher') {
    labelVariant = 'success';
  } else {
    labelVariant = 'info';
  }

  return (
    <span className={`label label-${labelVariant}`}>
      {type.toUpperCase()}
    </span>
  );
};

export default AccountType;
