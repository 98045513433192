import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectUserInfo } from "../../state/slices/authSlice";
import profilePlaceholder from "../../assets/profile_placeholder.jpg";

const Sidebar = () => {
  const userInfo = useSelector(selectUserInfo);

  const fullName = `${userInfo.firstName} ${userInfo.lastName}`;
  const photoUrl = userInfo.photoUrl ? userInfo.photoUrl : profilePlaceholder;

  return (
    <>
      {/* <!-- Left side column. contains the logo and sidebar --> */}
      <aside className='main-sidebar'>
        {/* <!-- sidebar: style can be found in sidebar.less --> */}
        <section className='sidebar'>
          {/* <!-- Sidebar user panel (optional) --> */}
          <div className='user-panel'>
            <div className='pull-left image'>
              <img
                src={photoUrl}
                className='img-circle user-image fluid'
                alt='User'
                style={{
                  objectFit: "fill",
                  width: "50px",
                  height: "46px",
                }}
              />
            </div>
            <div className='pull-left info'>
              <p>{fullName}</p>
              <div>
                <i className='fa fa-circle text-success'></i> Online
              </div>
            </div>
          </div>
          {/* <!-- Sidebar Menu --> */}
          <ul
            className='sidebar-menu'
            data-widget='tree'
          >
            {/* <!-- Optionally, you can add icons to the links --> */}
            <li>
              <Link to='/'>
                <i className='fa fa-dashboard'></i> <span>Dashboard</span>
              </Link>
            </li>
            <li>
              <Link to='/users'>
                <i className='fa fa-users'></i> <span>Users</span>
              </Link>
            </li>
            <li>
              <Link to='/todas'>
                <i className='fa fa-list'></i> <span>TODAs</span>
              </Link>
            </li>
            <li>
              <Link to='/shopper-groups'>
                <i className='fa fa-list'></i> <span>Shopper Groups</span>
              </Link>
            </li>
            <li>
              <Link to='/palengkes'>
                <i className='fa fa-list'></i> <span>Palengkes</span>
              </Link>
            </li>
            <li>
              <Link to='/ride-requests'>
                <i className='fa fa-list'></i> <span>Ride Requests</span>
              </Link>
            </li>
            <li>
              <Link to='/payments'>
                <i className='fa fa-list'></i> <span>Payments</span>
              </Link>
            </li>
            <li>
              <Link to='/transactions'>
                <i className='fa fa-list'></i> <span>Wallet Transactions</span>
              </Link>
            </li>
            <li>
              <Link to='/balance-requests'>
                <i className='fa fa-list'></i> <span>Balance Requests</span>
              </Link>
            </li>
          </ul>
        </section>
      </aside>
    </>
  );
};

export default Sidebar;
