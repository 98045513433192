import { DateTime } from "luxon";

const getFullYear = () => new Date().getFullYear();

const formatIsoDate = isoTimestamp => {
  const luxonDateTime = DateTime.fromISO(isoTimestamp, {
    zone: "Asia/Manila",
  });

  const humanReadableDate = luxonDateTime.toFormat("yyyy-MM-dd hh:mm a");

  return humanReadableDate;
};

const formatCurrency = value => {
  return <>&#8369; {value.toFixed(2)}</>;
};

function capitalizeWords(str) {
  return str
    .split(" ")
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

export { getFullYear, formatIsoDate, formatCurrency, capitalizeWords };
