// value = pending, approved, rejected
const BalanceRequestType = ({ value }) => {
  let labelVariant = "";

  if (value === "approved") {
    labelVariant = "success";
  } else if (value === "rejected") {
    labelVariant = "danger";
  } else {
    labelVariant = "warning";
  }

  return (
    <span className={`label label-${labelVariant}`}>{value.toUpperCase()}</span>
  );
};

export default BalanceRequestType;
