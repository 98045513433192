import React from "react";

const TransactionType = ({ value }) => {
  let labelVariant = "";

  if (value === "deposit") {
    labelVariant = "success";
  } else if (value === "withdraw") {
    labelVariant = "danger";
  } else {
    labelVariant = "warning";
  }

  return (
    <span className={`label label-${labelVariant}`}>
      {value ? value.toUpperCase() : "N/A"}
    </span>
  );
};

export default TransactionType;
