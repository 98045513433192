const Modal = ({ id, children }) => {
  return (
    <div
      className='modal fade'
      id={id}
      style={{ display: "none" }}
    >
      <div className='modal-dialog'>
        <div className='modal-content'>{children}</div>
      </div>
    </div>
  );
};

export default Modal;
