import React from "react";
import AccountStatus from "../../components/common/AccountStatus";
import AccountType from "../../components/common/AccountType";
import { formatIsoDate } from "../../utils/utils";
import imagePlaceholder from "../../assets/profile_placeholder.jpg";
import CircleLoader from "../../components/common/loaders/CircleLoader";
import Message from "../../components/common/Message";
import { useGetUserByIdQuery } from "../../state/slices/usersApiSlice";
import { useSelector } from "react-redux";
import { selectUserInfo } from "../../state/slices/authSlice";

const UserProfile = ({ userId }) => {
  const { accessToken } = useSelector(selectUserInfo);

  const { data, isLoading, error } = useGetUserByIdQuery({
    userId,
    accessToken,
  });

  const {
    firstName,
    lastName,
    email,
    isVerified,
    phone,
    photoUrl,
    type,
    createdAt,
  } = data || {};

  return (
    <div className='box box-solid box-widget widget-user-2'>
      <div className='box-header with-border'>
        <h3 className='box-title'>
          <i className='fa fa-user'></i> {firstName} {lastName}
        </h3>
      </div>
      <div
        className='box-body'
        style={{ fontSize: "1.5rem" }}
      >
        {isLoading && (
          <CircleLoader
            title='Loading...'
            size={50}
            center
          />
        )}

        {error && (
          <Message
            variant='danger'
            message={
              error?.data?.message || error?.error || "Something went wrong."
            }
          />
        )}

        {!isLoading && !error && (
          <>
            <div className='widget-user-header bg-red'>
              <div className='widget-user-image'>
                <img
                  className='img-circle'
                  style={{
                    width: "60px",
                    height: "60px",
                    borderRadius: "50%",
                    objectFit: "cover",
                  }}
                  src={photoUrl ? photoUrl : imagePlaceholder}
                  alt='User Avatar'
                />
              </div>
              <h3 className='widget-user-username'>
                {firstName} {lastName}
              </h3>
              <h5 className='widget-user-desc'>
                <AccountType type={type} />
              </h5>
            </div>
            <ul className='list-group list-group-flush'>
              <li className='list-group-item'>
                ID:
                <span className='pull-right'>{userId}</span>
              </li>
              <li className='list-group-item'>
                Name
                <span className='pull-right'>
                  {firstName} {lastName}
                </span>
              </li>
              <li className='list-group-item'>
                Email
                <span className='pull-right'>{email}</span>
              </li>

              <li className='list-group-item'>
                Phone
                <span className='pull-right'>+63{phone}</span>
              </li>

              <li className='list-group-item'>
                Account Status
                <span className='pull-right'>
                  <AccountStatus accountStatus={isVerified} />
                </span>
              </li>
              <li className='list-group-item'>
                Date Registered
                <span className='pull-right'>{formatIsoDate(createdAt)}</span>
              </li>
            </ul>
          </>
        )}
      </div>
    </div>
  );
};

export default UserProfile;
