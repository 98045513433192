import React from 'react';

const Footer = () => {
  return (
    <footer className='main-footer'>
      <strong>
        Copyright &copy; {new Date().getFullYear()}{' '}
        <a target='_blank' rel='noreferrer' href='https://sakay.me'>
          Sakay Me
        </a>
        .
      </strong>{' '}
      All rights reserved.
    </footer>
  );
};

export default Footer;
