import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import CircleLoader from "../components/common/loaders/CircleLoader";
import Message from "../components/common/Message";
import ContentHeader from "../components/layouts/ContentHeader";
import MainContent from "../components/layouts/MainContent";
import { selectUserInfo } from "../state/slices/authSlice";
import { formatIsoDate } from "../utils/utils";
import {
  useAddTodaMemberMutation,
  useGetTodaMembersByIdQuery,
  useRemoveTodaMemberMutation,
} from "../state/slices/todaMembersApiSlice";
import { toast } from "react-toastify";
import Modal from "../components/common/Modal";
import ModalHeader from "../components/common/ModalHeader";
import ModalBody from "../components/common/ModalBody";
import ModalFooter from "../components/common/ModalFooter";

const TodaManageMembersPage = () => {
  const { id } = useParams();
  const closeRef = useRef();

  // remove member
  const [todaMemberId, setTodaMemberId] = useState("");
  const [userId, setUserId] = useState("");
  // add member
  const [phone, setPhone] = useState("");
  const [role, setRole] = useState("driver");

  const { accessToken } = useSelector(selectUserInfo);

  const [removeTodaMember, { isLoadingRemove }] = useRemoveTodaMemberMutation();
  const [addTodaMember, { isLoadingAdd }] = useAddTodaMemberMutation();

  const {
    data: members,
    isLoading,
    error,
    refetch: refetchMembers,
  } = useGetTodaMembersByIdQuery({
    todaId: id,
    accessToken,
  });

  const deleteHandler = data => {
    setTodaMemberId(data.todaMemberId);
    setUserId(data.userId);
  };

  const cancelDeleteHandler = () => {
    setTodaMemberId("");
    setUserId("");
  };

  const executeRemoveMember = async () => {
    try {
      await removeTodaMember({
        todaId: id,
        userId,
        todaMemberId,
        accessToken,
      });

      closeRef.current.click();
      setTodaMemberId("");
      setUserId("");

      refetchMembers();

      toast.success("Successfully removed member.");
    } catch (e) {
      toast.error("Unable to remove member.");
    }
  };

  const submitForm = async e => {
    e.preventDefault();

    if (phone === null || phone.length === 0)
      return toast.error("Phone field is required.");

    try {
      await addTodaMember({ phone, role, todaId: id, accessToken }).unwrap();

      refetchMembers();

      setPhone("");
      setRole("driver");

      toast.success("Successfully added member.");
    } catch (e) {
      toast.error("Unable to add member.");
    }
  };

  return (
    <>
      <ContentHeader pageTitle='TODA Manage Members'>
        <ol className='breadcrumb'>
          <li>
            <Link to='/todas'>
              <i className='fa fa-list'></i> TODAs
            </Link>
          </li>

          <li>
            <Link to={`/todas/${id}`}>
              <i className='fa fa-info'></i> TODA Detail
            </Link>
          </li>
          <li>
            <Link>
              <i className='fa fa-users'></i> Manage Members
            </Link>
          </li>
        </ol>
      </ContentHeader>
      <MainContent>
        <div className='box box-solid'>
          <div className='box-header'>
            <h3 className='box-title'>
              <i className='fa fa-users'></i> TODA Members
            </h3>

            <div className='box-tools'>
              <button
                className='btn btn-sm btn-success '
                type='button'
                data-toggle='modal'
                data-target='#modal-add'
              >
                <i className='fa fa-plus'></i> Add Member
              </button>
            </div>
          </div>
          <div className='box-body table-responsive no-padding'>
            {isLoading && (
              <CircleLoader
                title='Loading...'
                size={50}
                center
              />
            )}

            {error && (
              <Message
                title='Error'
                variant='danger'
              >
                {error.data.message || error.error}
              </Message>
            )}

            {!isLoading && !error && (
              <>
                <table className='table table-hover'>
                  <tbody>
                    <tr>
                      <th>Name</th>
                      <th>Phone Number</th>
                      <th>Role</th>
                      <th>Added Date</th>
                      <th>Actions</th>
                    </tr>

                    {members.length === 0 ? (
                      <tr>
                        <td
                          colSpan={5}
                          align='center'
                        >
                          <strong>NO RECORDS FOUND.</strong>
                        </td>
                      </tr>
                    ) : (
                      members.map(member => {
                        const { id, userDetails, createdAt, role } = member;

                        return (
                          <tr key={userDetails?.id}>
                            <td>
                              {userDetails?.firstName} {userDetails?.lastName}
                            </td>
                            <td>+63{userDetails?.phone}</td>
                            <td>{role.toUpperCase()}</td>
                            <td>{formatIsoDate(createdAt)}</td>
                            <td>
                              <button
                                className='btn btn-sm btn-danger'
                                type='button'
                                data-toggle='modal'
                                data-target='#modal-remove'
                                onClick={() =>
                                  deleteHandler({
                                    userId: userDetails?.id,
                                    todaMemberId: id,
                                  })
                                }
                              >
                                <i className='fa fa-trash'></i> Remove
                              </button>
                            </td>
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                </table>
              </>
            )}
          </div>
        </div>
      </MainContent>

      <Modal id='modal-add'>
        <ModalHeader title='Add Member' />
        <ModalBody>
          <form>
            <div className='form-group'>
              <label htmlFor='phone'>Phone</label>

              <input
                type='number'
                className='form-control'
                id='phone'
                placeholder='Enter member phone'
                required
                value={phone}
                onChange={e => setPhone(e.target.value)}
              />
            </div>

            <div className='form-group'>
              <label htmlFor='role'>Role</label>

              <select
                name='role'
                id='role'
                className='form-control'
                value={role}
                onChange={e => setRole(e.target.value)}
              >
                <option value='driver'>Driver</option>
                <option value='dispatcher'>Dispatcher</option>
              </select>
            </div>
          </form>
        </ModalBody>
        <ModalFooter>
          <button
            type='button'
            className='btn btn-success '
            data-dismiss='modal'
            onClick={e => submitForm(e)}
          >
            Submit
          </button>

          <button
            type='button'
            className='btn btn-danger pull-right'
            data-dismiss='modal'
          >
            Cancel
          </button>
        </ModalFooter>
      </Modal>

      <Modal id='modal-remove'>
        <ModalHeader
          title='Remove Member'
          onClose={() => cancelDeleteHandler()}
        />
        <ModalBody>
          <p>You do want to remove this member?</p>
        </ModalBody>
        <ModalFooter>
          <button
            type='button'
            className='btn btn-success'
            data-dismiss='modal'
            onClick={() => executeRemoveMember()}
          >
            Yes
          </button>

          <button
            type='button'
            className='btn btn-danger '
            data-dismiss='modal'
            ref={closeRef}
            onClick={() => cancelDeleteHandler()}
          >
            Cancel
          </button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default TodaManageMembersPage;
