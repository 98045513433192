import React from 'react';

// you can color it by passing color yellow and this component will add bg- to it
const InfoBox = ({ iconClass, title, color, children }) => {
  return (
    <div className='info-box'>
      <span className={`info-box-icon bg-${color}`}>
        <i className={iconClass}></i>
      </span>

      <div className='info-box-content'>
        <span className='info-box-text'>{title}</span>
        <span className='info-box-number'>{children}</span>
      </div>
    </div>
  );
};

export default InfoBox;
