import React, { useState, useEffect } from "react";
import { auth } from "../config/firebase";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useLoginMutation } from "../state/slices/usersApiSlice";
import { selectUserInfo, setCredentials } from "../state/slices/authSlice";
import { toast } from "react-toastify";
import image from "../assets/sakay_logo@250x250.png";

const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userInfo = useSelector(selectUserInfo);

  const [login, { isLoading }] = useLoginMutation();

  useEffect(() => {
    if (userInfo) {
      navigate("/");
    }
  }, [userInfo, navigate]);

  const handleSubmit = async e => {
    e.preventDefault();

    try {
      const res = await signInWithEmailAndPassword(auth, email, password);
      const { uid, accessToken } = res.user;

      const authenticatedUser = await login({ uid, accessToken }).unwrap();

      dispatch(setCredentials(authenticatedUser));

      navigate("/");
    } catch (e) {
      toast.error("Invalid credentials, please try again.");
    }
  };

  return (
    <div
      className='login-page'
      style={{ height: "100%" }}
    >
      <div
        className='login-box'
        style={{ marginTop: 0, marginBottom: 0, paddingTop: "7%" }}
      >
        {/* /.login-logo */}
        <div className='login-box-body'>
          <img
            className='center-block'
            src={image}
            style={{
              borderRadius: "1%",
              width: 100,
              height: 100,
              marginBottom: 10,
            }}
            alt='Sakay Logo'
          />

          <p className='login-box-msg'>Sign in to start your session</p>
          <form onSubmit={handleSubmit}>
            <div className='form-group has-feedback'>
              <input
                type='email'
                className='form-control'
                placeholder='Email'
                required
                value={email}
                onChange={e => setEmail(e.target.value)}
              />
              <span className='glyphicon glyphicon-envelope form-control-feedback' />
            </div>
            <div className='form-group has-feedback'>
              <input
                type='password'
                className='form-control'
                placeholder='Password'
                required
                value={password}
                onChange={e => setPassword(e.target.value)}
              />
              <span className='glyphicon glyphicon-lock form-control-feedback' />
            </div>

            <button
              type='submit'
              className='btn btn-primary  btn-flat'
              disabled={isLoading}
            >
              Sign In
            </button>
          </form>

          <br />
          <a href='#'>I forgot my password</a>
        </div>
        {/* /.login-box-body */}
      </div>
    </div>
  );
};

export default LoginPage;
