import React from "react";
import { selectUserInfo } from "../../state/slices/authSlice";
import { useSelector } from "react-redux";
import { useGetWalletByIdQuery } from "../../state/slices/walletsApiSlice";
import Message from "../../components/common/Message";
import CircleLoader from "../../components/common/loaders/CircleLoader";

const ShopperGroupWallet = ({ shopperGroupId }) => {
  const { accessToken } = useSelector(selectUserInfo);

  const {
    data: walletDetail,
    isLoading,
    error,
  } = useGetWalletByIdQuery({ id: shopperGroupId, accessToken });

  const { accountNumber, balance, walletId } = walletDetail || {};

  return (
    <div className='box box-solid'>
      {isLoading && (
        <CircleLoader
          title='Loading...'
          size={50}
          center
        />
      )}

      {error && (
        <Message
          variant='danger'
          message={error.data.message || error.error}
        />
      )}

      {!isLoading && !error && (
        <>
          <div className='box-header with-border'>
            <h3 className='box-title'>
              <i className='fa fa-wallet'></i>
              <span style={{ fontWeight: "bold" }}>&#8369;</span> Wallet Details
            </h3>
          </div>
          <div
            className='box-body'
            style={{ fontSize: "1.5rem" }}
          >
            <ul className='list-group list-group-flush'>
              <li className='list-group-item'>
                Wallet ID:
                <span className='pull-right'>{walletId}</span>
              </li>
              <li className='list-group-item'>
                Account Number:
                <span className='pull-right'>#{accountNumber}</span>
              </li>
              <li className='list-group-item'>
                Available Balance:
                <span className='pull-right'>
                  <strong style={{ fontSize: "2rem" }}>
                    &#8369; {balance.toFixed(2)}
                  </strong>
                </span>
              </li>
            </ul>
            <div>
              <button className='btn btn-success'>Load Wallet</button>{" "}
              <button className='btn btn-success'>Payout</button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ShopperGroupWallet;
