import React from 'react';

const AccountStatus = ({ accountStatus }) => {
  let labelText = accountStatus ? 'Verified' : 'Unverified';
  let labelVariant = accountStatus ? 'success' : 'danger';

  return (
    <span className={`label label-${labelVariant}`}>{labelText}</span>
  );
};

export default AccountStatus;
