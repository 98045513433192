import React from "react";

const ContentHeader = ({ pageTitle, subTitle, children }) => {
  return (
    <section className='content-header'>
      <h1>
        {pageTitle}
        <small>{subTitle}</small>
      </h1>

      {children}
    </section>
  );
};

export default ContentHeader;
