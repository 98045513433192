import React from "react";
import { Link } from "react-router-dom";
import TransactionType from "../../components/common/TransactionType";
import { formatCurrency, formatIsoDate } from "../../utils/utils";
import { useGetUserTransactionsQuery } from "../../state/slices/usersApiSlice";
import CircleLoader from "../../components/common/loaders/CircleLoader";
import { useSelector } from "react-redux";
import { selectUserInfo } from "../../state/slices/authSlice";
import Message from "../../components/common/Message";

const UserTransactions = ({ userId }) => {
  const { accessToken } = useSelector(selectUserInfo);

  const {
    data: transactions,
    isLoading,
    error,
  } = useGetUserTransactionsQuery({ userId, accessToken });

  return (
    <div className='box box-solid'>
      <div className='box-header with-border'>
        <h3 className='box-title'>
          <i className='fa fa-list'></i> Wallet Transactions
        </h3>
      </div>
      <div className='box-body table-responsive no-padding'>
        {isLoading && (
          <CircleLoader
            title='Loading...'
            size={50}
            center
          />
        )}

        {error && (
          <Message
            variant='danger'
            message={
              error?.data?.message || error?.error || "Something went wrong"
            }
          />
        )}

        {!isLoading && !error && (
          <table className='table table-hover'>
            <tbody>
              <tr>
                <th>ID</th>
                <th>Source</th>
                <th>Transaction Type</th>
                <th>Amount</th>
                <th>Date</th>
              </tr>

              {transactions.length === 0 ? (
                <tr>
                  <td
                    colSpan={5}
                    align='center'
                  >
                    <strong>NO RECORDS FOUND.</strong>
                  </td>
                </tr>
              ) : (
                transactions.map(transaction => {
                  const {
                    transactionId,
                    amount,
                    createdAt,
                    source,
                    transactionType,
                  } = transaction;

                  return (
                    <tr key={transactionId}>
                      <td>
                        <Link to={`/transaction/${transactionId}`}>
                          {transactionId}
                        </Link>
                      </td>
                      <td>{source.toUpperCase()}</td>
                      <td>
                        <TransactionType value={transactionType} />
                      </td>
                      <td>
                        <strong>{formatCurrency(amount)}</strong>
                      </td>
                      <td>{formatIsoDate(createdAt)}</td>
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default UserTransactions;
