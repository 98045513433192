import React from "react";
import { Outlet, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectUserInfo } from "../../state/slices/authSlice";

const PrivateRoute = ({ redirectPath = "/login" }) => {
  const userInfo = useSelector(selectUserInfo);

  // if user is not authenticated redirect them to login and add replace to reset url history
  if (!userInfo) {
    return (
      <Navigate
        to={redirectPath}
        replace
      />
    );
  }

  // render the page/component you want to protect
  return <Outlet />;
};

export default PrivateRoute;
