import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import MainContent from "../../components/layouts/MainContent";
import ContentHeader from "../../components/layouts/ContentHeader";
import ShopperGroupMembers from "./ShopperGroupMembers";
import ShopperGroupDetails from "./ShopperGroupDetails";
import ShopperGroupWallet from "./ShopperGroupWallet";

const ShopperGroupDetailPage = () => {
  const { id: shopperGroupId } = useParams();

  return (
    <>
      <ContentHeader pageTitle='Shopper Group Details'>
        <ol className='breadcrumb'>
          <li>
            <Link to='/shopper-groups'>
              <i className='fa fa-list'></i> Shopper Groups
            </Link>
          </li>
          <li>
            <Link to='#'>
              <i className='fa fa-info'></i> Shopper Group Details
            </Link>
          </li>
        </ol>
      </ContentHeader>
      <MainContent>
        <div className='row'>
          <div className='col-md-6'>
            <ShopperGroupDetails shopperGroupId={shopperGroupId} />
          </div>

          <div className='col-md-6'>
            <ShopperGroupWallet shopperGroupId={shopperGroupId} />
          </div>
        </div>

        <ShopperGroupMembers shopperGroupId={shopperGroupId} />
      </MainContent>
    </>
  );
};

export default ShopperGroupDetailPage;
