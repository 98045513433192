import { RIDE_REQUESTS_URL } from "../../constants/constants";

import { apiSlice } from "./apiSlice";

export const rideRequestsApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getRideRequests: builder.query({
      query: ({ accessToken, limit, after, before, view, order }) => {
        let url = `${RIDE_REQUESTS_URL}?limit=${limit}`;

        if (after) {
          url += `&after=${after}`;
        }

        if (before) {
          url += `&before=${before}`;
        }

        if (view) {
          url += `&view=${view}`;
        }

        if (order) {
          url += `&order=${order}`;
        }

        return {
          url,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      keepUnusedDataFor: 60,
    }),
  }),
});

export const { useGetRideRequestsQuery } = rideRequestsApiSlice;
