import { TRANSACTIONS_URL } from "../../constants/constants";

import { apiSlice } from "./apiSlice";

export const transactionsApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getTransactions: builder.query({
      query: ({ accessToken, limit, after, before, view, order }) => {
        let url = `${TRANSACTIONS_URL}?limit=${limit}`;

        if (after) {
          url += `&after=${after}`;
        }

        if (before) {
          url += `&before=${before}`;
        }

        if (view) {
          url += `&view=${view}`;
        }

        if (order) {
          url += `&order=${order}`;
        }

        return {
          url,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      keepUnusedDataFor: 60,
    }),
  }),
});

export const { useGetTransactionsQuery } = transactionsApiSlice;
