import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "../../constants/constants";

export const apiSlice = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: headers => {
      headers.set("Content-Type", `application/json`);

      return headers;
    },
  }),
  tagTypes: ["User", "Toda"],
  endpoints: builder => ({}),
});
