import React from "react";
import BarLoader from "react-spinners/BarLoader";

const CircleLoader = ({ color, size, title, center, speedMultiplier }) => {
  return (
    <div
      style={
        center && {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: 16,
          flexDirection: "column",
        }
      }
    >
      <BarLoader
        color={color}
        size={size}
        aria-label='Loading...'
        data-testid='loader'
        speedMultiplier={speedMultiplier}
      />

      {title && (
        <p
          style={{
            fontSize: "1.75rem",
            marginTop: "1rem",
            fontStyle: "italic",
          }}
        >
          {" "}
          {title}
        </p>
      )}
    </div>
  );
};

export default CircleLoader;
