import React from "react";
import { Link, useParams } from "react-router-dom";

import ContentHeader from "../../components/layouts/ContentHeader";
import MainContent from "../../components/layouts/MainContent";

import TodaMembers from "./TodaMembers";
import TodaDetails from "./TodaDetails";
import TodaWallet from "./TodaWallet";

const TodaDetailsPage = () => {
  const { id: todaId } = useParams();

  return (
    <>
      <ContentHeader pageTitle='TODA Detail'>
        <ol className='breadcrumb'>
          <li>
            <Link to='/todas'>
              <i className='fa fa-list'></i> TODAs
            </Link>
          </li>
          <li>
            <Link>
              <i className='fa fa-info'></i> TODA Detail
            </Link>
          </li>
        </ol>
      </ContentHeader>
      <MainContent>
        <div className='row'>
          <div className='col-md-6'>
            <TodaDetails todaId={todaId} />
          </div>

          <div className='col-md-6'>
            <TodaWallet todaId={todaId} />
          </div>
        </div>

        <div className='row'>
          <div className='col-md-12'>
            <TodaMembers todaId={todaId} />
          </div>
        </div>

        <div className='box box-solid'>
          <div className='box-header with-border'>
            <h3 className='box-title'>
              <i className='fa fa-history'></i> Ride History
            </h3>
          </div>
          <div className='box-body table-responsive no-padding'>
            <table className='table table-hover'>
              <tbody>
                <tr>
                  <th>ID</th>
                  <th>Origin Location</th>
                  <th>Destination Location</th>
                  <th>Total Passenger</th>
                  <th>Status</th>
                  <th>Passenger Name</th>
                  <th>Driver Name</th>
                  <th>Car Details</th>
                  <th>Date Requested</th>
                </tr>

                <tr>
                  <td
                    colSpan={9}
                    align='center'
                  >
                    <strong>NO RECORDS FOUND.</strong>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </MainContent>
    </>
  );
};

export default TodaDetailsPage;
