const backendDevUrl = "http://localhost:3001";
const backendProdUrl = "https://api.sakay.me";
export const BASE_URL =
  process.env.NODE_ENV === "development" ? backendDevUrl : backendProdUrl;
export const USERS_URL = "api/users";
export const TODAS_URL = "api/todas";
export const TODA_MEMBERS_URL = "api/toda-members";
export const WALLETS_URL = "api/wallets";
export const RIDE_REQUESTS_URL = "/api/ride-requests";
export const TRANSACTIONS_URL = "/api/transactions";
export const AUTH_URL = "api/auth";
export const SHOPPER_GROUPS_URL = "api/shopper-groups";
export const BALANCE_REQUESTS_URL = "/api/balance-requests";
export const PALENGKES_URL = "/api/palengkes";

// folder storage bucket
export const STORAGE_USER_PHOTO_FOLDER = "sakay_user_photo";

// LOCAL STORAGE KEY
export const USER_INFO_KEY = "SMUserInfo";
