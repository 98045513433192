import React from 'react';
import ContentHeader from '../components/layouts/ContentHeader';
import MainContent from '../components/layouts/ContentHeader';
import { Link } from 'react-router-dom';

const PaymentsPage = () => {
  return (
    <>
      <ContentHeader pageTitle='Payments'>
        <ol className='breadcrumb'>
          <li>
            <Link to='/payments'>
              <i className='fa fa-list'></i> Payments
            </Link>
          </li>
        </ol>
      </ContentHeader>
      <MainContent></MainContent>
    </>
  );
};

export default PaymentsPage;
