import { PALENGKES_URL } from "../../constants/constants";

import { apiSlice } from "./apiSlice";

export const palengkesApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    createPalengke: builder.mutation({
      query: ({ palengke, accessToken }) => {
        console.log(palengke);
        return {
          url: `${PALENGKES_URL}`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          body: palengke,
        };
      },
      keepUnusedDataFor: 5,
    }),
    updatePalengke: builder.mutation({
      query: ({ palengke, accessToken }) => ({
        url: `${PALENGKES_URL}/${palengke.id}`,
        method: "PUT",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        body: {
          name: palengke.name,
          address: palengke.address,
        },
      }),
      keepUnusedDataFor: 5,
    }),
    getPalengkeCount: builder.query({
      query: ({ accessToken }) => ({
        url: `${PALENGKES_URL}/count`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }),
      keepUnusedDataFor: 5,
    }),
    getPalengkeById: builder.query({
      query: ({ palengkeId, accessToken }) => ({
        url: `${PALENGKES_URL}/id/${palengkeId}`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }),
      keepUnusedDataFor: 5,
    }),
    getPalengkes: builder.query({
      query: ({ accessToken }) => ({
        url: PALENGKES_URL,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }),
      keepUnusedDataFor: 5,
    }),
  }),
});

export const {
  useGetPalengkeByIdQuery,
  useGetPalengkeCountQuery,
  useGetPalengkesQuery,
  useCreatePalengkeMutation,
  useUpdatePalengkeMutation,
} = palengkesApiSlice;
