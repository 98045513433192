import { useState } from "react";
import { useSelector } from "react-redux";
import { selectUserInfo } from "../state/slices/authSlice";
import { useCreateUserMutation } from "../state/slices/usersApiSlice";
import { toast } from "react-toastify";
import MainContent from "../components/layouts/MainContent";
import ContentHeader from "../components/layouts/ContentHeader";
import { Link, useNavigate } from "react-router-dom";
import { capitalizeWords } from "../utils/utils";

const CreateUserPage = () => {
  const { id, accessToken } = useSelector(selectUserInfo);

  const [createUser, { isLoading }] = useCreateUserMutation();

  const navigate = useNavigate();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [type, setType] = useState("");

  const handleSubmit = async e => {
    e.preventDefault();

    const capitalizedFirstName = capitalizeWords(firstName);
    const capitalizedLastName = capitalizeWords(lastName);

    try {
      await createUser({
        firstName: capitalizedFirstName,
        lastName: capitalizedLastName,
        email,
        phone,
        type,
        accessToken,
        createdBy: id,
      }).unwrap();

      navigate("/users");

      toast.success(
        `User ${capitalizedFirstName} ${capitalizedLastName} is created.`,
      );
    } catch (e) {
      console.log(e);

      if (e.data.errors) {
        toast.error(e.data.errors[0].message);
      } else if (e.data.message) {
        toast.error(e.data.message);
      } else {
        toast.error("Something went wrong, please try again.");
      }
    }
  };

  return (
    <>
      <ContentHeader pageTitle='Create Shopper User'>
        <ol className='breadcrumb'>
          <li>
            <Link to='/users'>
              <i className='fa fa-users'></i> Users
            </Link>
          </li>

          <li>
            <Link>
              <i className='fa fa-plus'></i> Create User
            </Link>
          </li>
        </ol>
      </ContentHeader>

      <MainContent>
        <div className='box box-solid'>
          <div className='box-body'>
            <form onSubmit={handleSubmit}>
              <div className='form-group'>
                <label htmlFor='type'>Account Type</label>

                <select
                  type='text'
                  className='form-control'
                  id='type'
                  required
                  value={type}
                  onChange={e => setType(e.target.value)}
                >
                  <option value=''>Select type</option>
                  <option value='shopper'>Shopper</option>
                  <option value='dispatcher'>Dispatcher</option>
                </select>
              </div>

              <div className='form-group'>
                <label htmlFor='firstName'>First Name</label>

                <input
                  type='text'
                  className='form-control'
                  id='firstName'
                  placeholder='Enter first name'
                  required
                  value={firstName}
                  onChange={e => setFirstName(e.target.value)}
                />
              </div>

              <div className='form-group'>
                <label htmlFor='lastName'>Last Name</label>

                <input
                  type='text'
                  className='form-control'
                  id='lastName'
                  placeholder='Enter last name'
                  required
                  value={lastName}
                  onChange={e => setLastName(e.target.value)}
                />
              </div>

              <div className='form-group'>
                <label htmlFor='email'>Email</label>

                <input
                  type='email'
                  className='form-control'
                  id='email'
                  placeholder='Enter email'
                  required
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                />
              </div>

              <div className='form-group'>
                <label htmlFor='phone'>
                  Phone <small className='text-muted'>Ex. 9238964687</small>
                </label>

                <input
                  type='text'
                  className='form-control'
                  id='phone'
                  placeholder='Enter user phone'
                  required
                  value={phone}
                  onChange={e => setPhone(e.target.value)}
                />
              </div>

              <button
                type='submit'
                className='btn btn-success'
                disabled={isLoading}
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      </MainContent>
    </>
  );
};

export default CreateUserPage;
