import React from 'react';

const Message = ({
  title = 'Message Alert!',
  variant = 'info',
  message = 'Something went wrong...',
  children
}) => {
  let icon = 'info';

  if (variant === 'danger') {
    icon = 'ban';
  } else if (variant === 'success') {
    icon = 'check';
  } else if (variant === 'warning') {
    icon = 'warning';
  } else {
    icon = 'info';
  }

  return (
    <div className={`alert alert-${variant}`}>
      <h4>
        <i className={`icon fa fa-${icon}`}></i> {title}
      </h4>
      <p>{message}</p>
      {children}
    </div>
  );
};

export default Message;
