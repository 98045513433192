import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectUserInfo } from "../../state/slices/authSlice";
import { Link } from "react-router-dom";
import ContentHeader from "../../components/layouts/ContentHeader";
import MainContent from "../../components/layouts/MainContent";
import PalengkeDetails from "./PalengkeDetails";
import { useGetPalengkeByIdQuery } from "../../state/slices/palengkesApiSlice";
import PalengkeDetailsMap from "./PalengkeDetailsMap";

const PalengkeDetailsPage = () => {
  const { id } = useParams();
  const { accessToken } = useSelector(selectUserInfo);

  const {
    data: palengke,
    isLoading,
    error,
    refetch,
  } = useGetPalengkeByIdQuery({ palengkeId: id, accessToken });

  return (
    <>
      <ContentHeader pageTitle='Palengkes'>
        <ol className='breadcrumb'>
          <li>
            <Link to='/palengkes'>
              <i className='fa fa-list'></i> Palengkes
            </Link>
          </li>
          <li>
            <Link to='#'>
              <i className='fa fa-info'></i> Palengke Details
            </Link>
          </li>
        </ol>
      </ContentHeader>

      <MainContent>
        <div className='row'>
          <div className='col-md-6'>
            <PalengkeDetails
              palengke={palengke}
              isLoading={isLoading}
              error={error}
              onSubmit={refetch}
            />
          </div>

          <div className='col-md-6'>
            <PalengkeDetailsMap
              palengke={palengke}
              isLoading={isLoading}
              error={error}
            />
          </div>
        </div>
      </MainContent>
    </>
  );
};

export default PalengkeDetailsPage;
