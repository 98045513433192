import { BALANCE_REQUESTS_URL } from "../../constants/constants";

import { apiSlice } from "./apiSlice";

export const transactionsApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getBalanceRequests: builder.query({
      query: ({ accessToken }) => {
        return {
          url: `${BALANCE_REQUESTS_URL}`,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      keepUnusedDataFor: 60,
    }),
    getBalanceRequestById: builder.query({
      query: ({ balanceRequestId, accessToken }) => ({
        url: `${BALANCE_REQUESTS_URL}/${balanceRequestId}`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }),
      keepUnusedDataFor: 60,
    }),
    updateBalanceRequest: builder.mutation({
      query: ({ updatedFields, accessToken }) => ({
        url: `${BALANCE_REQUESTS_URL}/${updatedFields.id}`,
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        body: updatedFields,
      }),
    }),
  }),
});

export const {
  useGetBalanceRequestsQuery,
  useGetBalanceRequestByIdQuery,
  useUpdateBalanceRequestMutation,
} = transactionsApiSlice;
